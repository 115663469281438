<!-- alert 컴포넌트..!
    
-->
<template>
    <div class="gmx_check_box" :class="cl">
        <input :id="newID" type="checkbox" :checked="modelValue" @change="handleChange">
        <label :for="newID"></label>
        <label v-if="label" for="newID" class="gmx_check_label">{{ label }}</label>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const emit = defineEmits(['update:modelValue']);

defineProps({
    modelValue : {
        type : Boolean,
        default : false
    },
    label : String,
    cl : String
});


const newID = Math.random().toString(36).substring(2, 16);

function handleChange(event) {
    // 
  emit('update:modelValue', event.target.checked);
}

</script>

<style lang="scss" scoped>

    .gmx_check_box {
        display: flex;
        align-items: center;
        margin-right: 5px;
        
        label {
            cursor: pointer;
        }

        input {
            display: none;
        }
        input + label {
            position: relative;
            width: 18px;
            height: 18px;
            border: 1px solid $gmx-box-br-color;
            border-radius: $border-radius1;
        }
        .gmx_check_label {
            margin-left: 6px;
            font-size: $font-size1;
            color: $gmx-font-sub-color2;
            user-select: none;
            cursor:pointer;
        }

        input:checked + label {
            background-color: $gmx-main-color;
            border: 1px solid $gmx-main-color;
        }

        input:checked + label:before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            background-image: url(@/assets/img/icn_check.svg);
            background-repeat: no-repeat;
            background-position: center;
            filter: brightness(0) invert(1);
        }
    }
</style>