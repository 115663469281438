<template>
    <div class="gmx_alert" v-if="isShow" >
        <div class="gmx_alert_container flex flex_column flex_center_3">
                <div name="head" class="flex flex_column flex_center_3">
                    <template v-for="(item, index) in typeList" :key="index">
                        <template v-if="type === index.toString()">
                            <div class="alert_type flex flex_center_3" :class="`type_${index}`"></div>
                        </template>
                    </template>
                    <h3 class="alert_title">{{ title }}</h3>
                </div>
                <div name="body">
                    {{ msg }}
                </div>
                <div name="footer" class="button_container">
                    <GmxButton title="확인" cl="d" @click="_handleClickCancle"></GmxButton>
                </div>
        </div>
    </div>
</template>

<script setup>
import {  ref, defineExpose } from 'vue';
import GMXGlobal from '@/js/global';
import GmxButton from '../button/GmxButton.vue';

// MSG_STATE_ERROR : '0', //오류
// MSG_STATE_WRAN : '1', //경고
// MSG_STATE_INFO : '2', //정보
// MSG_STATE_OK : '3', //성공
const title = ref('');
const msg = ref('');
const type = ref(GMXGlobal.MSG_STATE_INFO);
const isShow = ref(false);
const callBack = ref(null);

const showAlert = (_type, _title, _msg, _callBack) => {
    type.value = _type;
    title.value = _title;
    msg.value = _msg;
    isShow.value = true;
    callBack.value = _callBack;
};

const _handleClickCancle = () => {
    if ( callBack.value ) callBack.value(false);
    isShow.value = false;
}

defineExpose({
    showAlert
})

//이미지를 위해 따로 구성했음.
const typeList = [
    {
        typeText : "오류"
    },
    {
        typeText : "경고"
    },
    {
        typeText : "정보"
    },
    {
        typeText : "성공"
    }
]

</script>

<style lang="scss" scoped>

.gmx_alert {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);

    .gmx_alert_container {
        width: 458px;
        padding: 28px 0;
        background-color: $gmx-theme-color;
        border-radius: $border-radius5;
        .alert_title {
            margin: 20px 0;
            font-family: $font-type2;
        }
        .alert_type {
            width: 50px;
            height: 50px;
            border-radius: $border-radius0;
            font-size: $font-size8;
            &.type_0 {
                background-color: rgba($alert-color1, 0.1);
                color: $alert-color1;
                &:after {
                    content: "!"
                }
            }
            &.type_1 {
                background-color: rgba($alert-color4, 0.1);
                color: $alert-color4;
                &:after {
                    content: "!"
                }
            }
            &.type_2 {
                background-color: rgba($alert-color3, 0.1);
                color: $alert-color3;
                &:after {
                    content: "i"
                }
            }
            &.type_3 {
                background-color: rgba($alert-color2, 0.1);
                color: $alert-color2;
                &:after {
                    content: "✓"
                }
            }
        }
        .button_container {
            margin-top: 30px;
        }
    }
}


</style>