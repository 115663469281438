<!-- 
    Toast 컴포넌트
     
-->
<template>
    <transition name="slide-fade" mode="out-in">
        <div v-if="show" class="gmx_toast_container">
            <template v-for="(item, index) in typeList" :key="index">
                <template v-if="type === index.toString()">
                    <div class="gmx_toast_box flex flex_center_1" :class="`type_${index}`">
                        <span>{{ msg }}</span>
                        <GmxButton cl="c" @click="hideToast"></GmxButton>
                    </div>
                </template>
            </template>
        </div>
    </transition>
</template>

<script setup>

import { ref, defineExpose } from 'vue';
import { GmxButton } from '@/components/gmx';
import GMXGlobal from '@/js/global';
// MSG_STATE_ERROR : '0', //오류
// MSG_STATE_WRAN : '1', //경고
// MSG_STATE_INFO : '2', //정보
// MSG_STATE_OK : '3', //성공
const msg = ref('');
const type = ref(GMXGlobal.MSG_STATE_INFO);

let show = ref(false);

const showToast = (_type, _msg) => {
    type.value = _type;
    msg.value = _msg;
    show.value = true;

    // 일정 시간이 지난 후에 토스트를 숨깁니다.
    setTimeout(() => {
        hideToast();
    }, 3000);

};

const hideToast = () => {
    show.value = false;
};

//이미지를 위해 따로 구성했음.
const typeList = [
    {
        typeText : "오류"
    },
    {
        typeText : "경고"
    },
    {
        typeText : "정보"
    },
    {
        typeText : "성공"
    }
]

defineExpose({
    showToast
})


</script>

<style lang="scss" scoped>
    .gmx_toast_container {
        position: fixed;
        right: 15px;
        bottom: 15px;
        z-index: 99999;
        .gmx_toast_box {
            width: 500px;
            height: 60px;
            padding: 0 25px;
            border-radius: 30px;
            color: $gmx-theme-color;
            &::before {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 22px;
                height: 22px;
                margin-right: 10px;
                border-radius: $border-radius0;
                background-color: $gmx-theme-color;
            }
            &.type_0 {
                background-color: $alert-color1;
                &::before {
                    content: "!";
                    color: $alert-color1;
                }
            }
            &.type_1 {
                background-color: $alert-color4;
                &::before {
                    content: "!";
                    color: $alert-color4;
                }
            }
            &.type_2 {
                background-color: $alert-color3;
                &::before {
                    content: "i";
                    color: $alert-color3;
                }
            }
            &.type_3 {
                background-color: $alert-color2;
                &::before {
                    content: "✓";
                    color: $alert-color2;
                }
            }
            > :last-child {
                margin-left: auto;
            }
            ::v-deep button {
                width: 24px;
                height: 24px;
                background-image: url(@/assets/img/icn_x_w.png);
            }
        }
    }
</style>