
const CONTEXT_GUIDE_NAME = 'guide';
//코드 분리하자..
const guideRouter = [
 
  // 가이드용... Object 함치는 함수 또는 파일 분할 필요할 듯?
  {
    path: `/${CONTEXT_GUIDE_NAME}/code`,
    name: 'code',
    components : {
      guide : () => import('@/views/guide/CodeGuideView.vue') 
    }
  },
  {
    path: `/${CONTEXT_GUIDE_NAME}/button`,
    name: 'button',
    components : {
      guide : () => import('@/views/guide/ButtonView.vue') 
    }
  },
  {
    path: `/${CONTEXT_GUIDE_NAME}/checkbox`,
    name: 'checkbox',
    components : { 
      guide : () => import('@/views/guide/CheckBoxView.vue') 
    }
  },
  {
    path: `/${CONTEXT_GUIDE_NAME}/textbox`,
    name: 'textbox',
    components : {
      guide : () => import('@/views/guide/TextBoxView.vue') 
    }
  },
  {
    path: `/${CONTEXT_GUIDE_NAME}/cardlist`,
    name: 'cardlist',
    components : {
      guide : () => import('@/views/guide/CardListView.vue') 
    }
  },
  {
    path: `/${CONTEXT_GUIDE_NAME}/toggle`,
    name: 'toggle',
    components : {
      guide : () => import('@/views/guide/ToggleView.vue') 
    }
  },
  {
    path: `/${CONTEXT_GUIDE_NAME}/selectbox`,
    name: 'selectbox',
    components : {
      guide : () => import('@/views/guide/SelectBoxView.vue') 
    }
  },
  {
    path: `/${CONTEXT_GUIDE_NAME}/alert`,
    name: 'alert',
    components : {
      guide : () => import('@/views/guide/AlertView.vue') 
    }
  },
  {
    path: `/${CONTEXT_GUIDE_NAME}/confirm`,
    name: 'confirm',
    components : {
      guide : () => import('@/views/guide/ConfirmView.vue') 
    }
  },
  {
    path: `/${CONTEXT_GUIDE_NAME}/dialog`,
    name: 'dialog',
    components : {
      guide : () => import('@/views/guide/DialogView.vue') 
    }
  },
  {
    path: `/${CONTEXT_GUIDE_NAME}/toast`,
    name: 'toast',
    components : {
      guide : () => import('@/views/guide/ToastView.vue') 
    }
  },
  {
    path: `/${CONTEXT_GUIDE_NAME}/tag`,
    name: 'tag',
    components : {
      guide : () => import('@/views/guide/TagView.vue') 
    }
  },
]

export default guideRouter;