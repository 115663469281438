
export default {

    /**
     * 년도 조회
     * @returns 
     */
    getY : () => {
        const now = new Date();
        const year = now.getFullYear().toString().padStart(4, '0');
        return `${year}`;
    },


    /**
     * 각 컬럼의 타입 별로.. table에 컨텐츠 만들기 위해 타입 지정..!
     * - 몰러.
     * 
     * @param {} refData 
     * @returns 
     */
    getYMDH24mmss : () => {
        const now = new Date();
        const year = now.getFullYear().toString().padStart(4, '0');
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        
        return `${year}${month}${day}${hours}${minutes}${seconds}`;
    },
    /**
     * 각 컬럼의 타입 별로.. table에 컨텐츠 만들기 위해 타입 지정..!
     * - 몰러.
     * 
     * @param {} refData 
     * @returns 
     */
    getYMD : () => {
        const now = new Date();
        const year = now.getFullYear().toString().padStart(4, '0');
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        
        return `${year}${month}${day}`;
    },

    /**
     * 날짜 타입을 변경한다...
     *  - 코드 스
     * @param {*} str
     * @param isTime 일시까지 표출.
     * @returns
     */
    convertDateData: ( str, isTime ) => {

        if (!str || str.trim().length < 6) return '';
        if ( str.indexOf('-') != -1 && str.trim().length > 8) return str;
        let strDate = str.trim();
        //console.log(str);
        const y = str.slice(0, 4);
        const m = str.slice(4, 6);
        let d = Number(str.slice(6,8)) || '';
        d = d < 10 ? '0' + d : d; 
        if (d === '0' ) d= '01';
        strDate = `${y}-${m}-${d}`;

        
        if(isTime && str.length == 14) {
            strDate += ` ${str.slice(8, 10)}:${str.slice(10, 12)}:${str.slice(12, 14)}`;
        }
        
        return strDate;
    },

    
    /**
     * 조회 기간이 유효한지 체크한다
     * @param {*} stDate
     * @param {*} edDate
     * @returns 
     */
    searchTimechk : (stDate, edDate) => {
        if(stDate && edDate){
            if( stDate > edDate)  {
                return false;
            }else return true;
        }else return true;
    },

    /**
     * 시간데이터의 형식을 맞춘다.
     * 남는 길이만큼 0으로 채운다.
     * 
     * @param timeStr
     * @param len
     * @returns 
     */
    formatTime : (timeStr, len) => {
        var zerosToPad = len - timeStr.length;
        if (zerosToPad <= 0) {
            return timeStr; 
        } else {
            var paddedString = timeStr + "0".repeat(zerosToPad);
            return paddedString;
        }
    },
    
    /**
     * 연도 리스트를 가져온다.
     * 
     * @param {*} stdYear 
     */
    getYears : (startYear, endYear) => {
        const currentYear = new Date().getFullYear();
        
        startYear = startYear || currentYear-1;
      
        // 기본적으로 endYear가 주어지지 않았다면 2025로 설정
        endYear = endYear || currentYear;
      
        const yearList = [];
      
        for (let year = startYear; year <= endYear; year++) {
          yearList.push(year);
        }
      
        return yearList;

    },

    currentYDate : (isCurrent) => {
        const today = new Date();
        const thisYear = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');

        return isCurrent ? `${thisYear}-${month}-${day}`: `${thisYear}-01-01`;
    },


    getMonthFirstDay : (y,m) => {

        const today = new Date();

        if (!y) {
            y = today.getFullYear();
        }
        if (!m ) {
            m = today.getMonth()+1;
        }

        const newDate = new Date(y, m - 1, 1);

        const year = newDate.getFullYear().toString().padStart(4, '0');
        const month = (newDate.getMonth()+1).toString().padStart(2, '0');
        const day = newDate.getDate().toString().padStart(2, '0');

        return `${year}${month}${day}`;
    },

    getMonthLastDay : (y,m) => {
        const today = new Date();
        
        if (!y) {
            y = today.getFullYear();
        }
        if (!m ) {
            m = today.getMonth()+1;
        }

        const nextMonthFirstDay = new Date(y, (m - 1)+1, 1);
        const newDate = new Date(nextMonthFirstDay - 1);
        
        const year = newDate.getFullYear().toString().padStart(4, '0');
        const month = (newDate.getMonth()+1).toString().padStart(2, '0');
        const day = newDate.getDate().toString().padStart(2, '0');

        return `${year}${month}${day}`;
    }
}