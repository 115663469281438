<template>
  <p  class="gmx_final">
   <ConfettiExplosion :particleCount="50" shouldDestroyAfterDone="false"   :colors="colorList"/>
  </p>
   <div class="wrap">
     <div>
       <img width="200px" src="@/assets/img/gmxLogo_icon.png"/>
       
        <div class="margin_top">
          <div class="bo">E-지원 시스템이 베타 오픈되었습니다.</div>
          <div >현재 접속하고 계신 주소는 곧 중단 될 예정이며</div>
          <div ><strong>아래 버튼을 클릭하시면 베타 오픈된 E-지원을 만나보실 수 있습니다.!</strong></div>
        </div>
        <div style="color:#d1d1d1">-</div>
        <div>
          <div>그간 요청해주신 내용을 바탕으로 시스템 안정화와 편의성 개선 작업을 진행했습니다.</div>
          <div>베타 오픈 기간 동안 사용하시면서 불편하신 부분들은 언제든지 이야기 해주시면 적극적으로 반영하겠습니다.</div>
        </div>
        
        <div class="">항상 고생 많으십니다. 감사합니다. :)</div>
        <div class="margin_top">
          <div class="line"></div>
          <button @click="go">E-지원 시스템(bata) 이동하기 > </button>
        </div>

      </div>
    </div>
</template>

<script setup>

import ConfettiExplosion from "vue-confetti-explosion";
import { ref } from "vue";


const colorList = ref(['#FF5E5C', '#18A0FB', '#8DC63F', '#F5C33E']);

 const go = () => {
    location.href = 'http://10.1.73.83/ezone/login';
  }
</script>

<style scope lang="scss">
  .gmx_final {
        width: 100%;
        position: fixed;
        text-align: center;
        left:50%;
        top:30%;
   }

   .confetti-container {
    position: fixed !important;
   }

.wrap {
    width:100%;
    height: 100vh;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      position: absolute;
      text-align: center;
    /* 원하는 스타일 */
     transform: translate(0%, 0%);
      img {
        margin: 0 auto;
      }

      .line {
        background : #dedede;
        width: 100%;
        height:1px;
      }
      .tit {
        margin-top:14px;

        font-family: $font-type3;
        font-size : 27px;
        letter-spacing: -0.84px;
        color: #000000;
        opacity: 1;
      }

      div {
        font-size:17px;
        font-family: $font-type0;
        color: #454545;

        strong {
          color:#377EF9;
          font-family: $font-type3;
        }

        button{
          margin-top:40px;
          background: transparent linear-gradient(104deg, var(--unnamed-color-377ef9) 0%, #00B5F9 100%) 0% 0% no-repeat padding-box;
background: transparent linear-gradient(104deg, #377EF9 0%, #00B5F9 100%) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 11px #5053A159;
border-radius: 6px;
width: 337px;
height: 76px;
font-size:20px;
color: #FFFFFF;
        }
      }
    }


    .bo {
      font-family: $font-type3 !important;
      font-size : 27px;
      color:#000000;
      margin-bottom:40px;
    }
   
}

.margin_top {
  margin-top:50px;
}


</style>