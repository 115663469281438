export default class LEA {

    constructor(key) {

        if ( !key ) key = 'ahjezoneasgeomex';
        
        this.BLOCKSIZE = 16; // 128bit 고정
        this.BLOCKMASK = 0xfffffff0;
        this.delta = [0xc3efe9db, 0x44626b02, 0x79e27c8a, 0x78df30ec, 0x715ea49e, 0xc785da0a, 0xe04ef22a, 0xe5c40957];

        this.generateRoundKeys(key);
        this.buffer = new Uint8Array(this.BLOCKSIZE);
        this.block = new Int32Array(this.BLOCKSIZE / 4);
        this.reset();
    }

    base64Encode(buffer) {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;

        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }

        return btoa(binary);
    }

    base64Decode(base64String) {
        const binary = atob(base64String);
        const length = binary.length;
        const buffer = new Uint8Array(length);

        for (let i = 0; i < length; i++) {
            buffer[i] = binary.charCodeAt(i);
        }

        return buffer;
    }

    reset() {
        this.bufferOffset = 0;
        this.buffer.fill(0);
        this.block.fill(0);
    }

    encrypt(plain) {
        this.reset();
        this.mode = 'ENCRYPT';
        return this.doFinal(plain);
    }

    decrypt(enc) {
        this.reset();
        this.mode = 'DECRYPT';
        return this.doFinal(enc);
    }

    doFinal(msg) {
        const part1 = this.update(msg);
        const part2 = this.doFinalWithPadding();

        const len1 = part1 ? part1.length : 0;
        const len2 = part2 ? part2.length : 0;

        const out = new Uint8Array(len1 + len2);

        if (len1 > 0) {
            out.set(part1, 0);
        }

        if (len2 > 0) {
            out.set(part2, len1);
        }

        return out;
    }

    getOutputSize(len) {
        const size = ((len + this.bufferOffset) & this.BLOCKMASK) + this.BLOCKSIZE;
        if (this.mode === 'ENCRYPT') {
            return size;
        }
        return len;
    }

    getUpdateOutputSize(len) {
        if (this.mode === 'DECRYPT') {
            return (len + this.bufferOffset - this.BLOCKSIZE) & this.BLOCKMASK;
        }
        return (len + this.bufferOffset) & this.BLOCKMASK;
    }

    update(msg) {
        if (this.mode === 'DECRYPT') {
            return this.decryptWithPadding(msg);
        }

        if (!msg) {
            return null;
        }

        let len = msg.length;
        const gap = this.buffer.length - this.bufferOffset;
        let inOff = 0;
        let outOff = 0;
        const out = new Uint8Array(this.getUpdateOutputSize(len));

        if (len >= gap) {
            this.buffer.set(msg.subarray(inOff, inOff + gap), this.bufferOffset);
            outOff += this.processBlock(this.buffer, 0, out, outOff);

            this.bufferOffset = 0;
            len -= gap;
            inOff += gap;

            while (len >= this.buffer.length) {
                outOff += this.processBlock(msg, inOff, out, outOff);
                len -= this.BLOCKSIZE;
                inOff += this.BLOCKSIZE;
            }
        }

        if (len > 0) {
            this.buffer.set(msg.subarray(inOff, inOff + len), this.bufferOffset);
            this.bufferOffset += len;
        }

        return out;
    }

    decryptWithPadding(msg) {
        if (!msg) {
            return null;
        }

        let len = msg.length;
        const gap = this.buffer.length - this.bufferOffset;
        let inOff = 0;
        let outOff = 0;
        const out = new Uint8Array(this.getUpdateOutputSize(len));

        if (len > gap) {
            this.buffer.set(msg.subarray(inOff, inOff + gap), this.bufferOffset);
            outOff += this.processBlock(this.buffer, 0, out, outOff);

            this.bufferOffset = 0;
            len -= gap;
            inOff += gap;

            while (len > this.buffer.length) {
                outOff += this.processBlock(msg, inOff, out, outOff);
                len -= this.BLOCKSIZE;
                inOff += this.BLOCKSIZE;
            }
        }

        if (len > 0) {
            this.buffer.set(msg.subarray(inOff, inOff + len), this.bufferOffset);
            this.bufferOffset += len;
        }

        return out;
    }

    doFinalWithPadding() {
        let out;

        if (this.mode === 'ENCRYPT') {
            this.pad(this.buffer, this.bufferOffset);
            out = new Uint8Array(this.getOutputSize(0));
            this.processBlock(this.buffer, 0, out, 0);
        } else {
            const blk = new Uint8Array(this.BLOCKSIZE);
            this.processBlock(this.buffer, 0, blk, 0);
            out = this.unpad(blk);
        }

        return out;
    }

    generateRoundKeys(mk) {
        if (!mk || (mk.length !== 16 && mk.length !== 24 && mk.length !== 32)) {
            throw new Error('Illegal key');
        }

        const T = new Int32Array(8);

        this.rounds = (mk.length >> 1) + 16;
        this.roundKeys = new Array(this.rounds).fill().map(() => new Int32Array(6));

        this.pack(mk, 0, T, 0, 16);

        if (mk.length > 16) {
            this.pack(mk, 16, T, 4, 8);
        }

        if (mk.length > 24) {
            this.pack(mk, 24, T, 6, 8);
        }

        if (mk.length === 16) {
            for (let i = 0; i < 24; ++i) {
                const temp = this.ROL(this.delta[i & 3], i);

                this.roundKeys[i][0] = T[0] = this.ROL(T[0] + this.ROL(temp, 0), 1);
                this.roundKeys[i][1] = this.roundKeys[i][3] = this.roundKeys[i][5] = T[1] = this.ROL(T[1] + this.ROL(temp, 1), 3);
                this.roundKeys[i][2] = T[2] = this.ROL(T[2] + this.ROL(temp, 2), 6);
                this.roundKeys[i][4] = T[3] = this.ROL(T[3] + this.ROL(temp, 3), 11);
            }
        } else if (mk.length === 24) {
            for (let i = 0; i < 28; ++i) {
                const temp = this.ROL(this.delta[i % 6], i);

                this.roundKeys[i][0] = T[0] = this.ROL(T[0] + this.ROL(temp, 0), 1);
                this.roundKeys[i][1] = T[1] = this.ROL(T[1] + this.ROL(temp, 1), 3);
                this.roundKeys[i][2] = T[2] = this.ROL(T[2] + this.ROL(temp, 2), 6);
                this.roundKeys[i][3] = T[3] = this.ROL(T[3] + this.ROL(temp, 3), 11);
                this.roundKeys[i][4] = T[4] = this.ROL(T[4] + this.ROL(temp, 4), 13);
                this.roundKeys[i][5] = T[5] = this.ROL(T[5] + this.ROL(temp, 5), 17);
            }
        } else {
            for (let i = 0; i < 32; ++i) {
                const temp = this.ROL(this.delta[i & 7], i & 0x1f);

                this.roundKeys[i][0] = T[(6 * i + 0) & 7] = this.ROL(T[(6 * i + 0) & 7] + temp, 1);
                this.roundKeys[i][1] = T[(6 * i + 1) & 7] = this.ROL(T[(6 * i + 1) & 7] + this.ROL(temp, 1), 3);
                this.roundKeys[i][2] = T[(6 * i + 2) & 7] = this.ROL(T[(6 * i + 2) & 7] + this.ROL(temp, 2), 6);
                this.roundKeys[i][3] = T[(6 * i + 3) & 7] = this.ROL(T[(6 * i + 3) & 7] + this.ROL(temp, 3), 11);
                this.roundKeys[i][4] = T[(6 * i + 4) & 7] = this.ROL(T[(6 * i + 4) & 7] + this.ROL(temp, 4), 13);
                this.roundKeys[i][5] = T[(6 * i + 5) & 7] = this.ROL(T[(6 * i + 5) & 7] + this.ROL(temp, 5), 17);
            }
        }
    }

    processBlock(inData, inOff, outData, outOff) {
        if (!inData || !outData) {
            throw new Error('inData and outData should not be null');
        }

        if (inData.length - inOff < this.BLOCKSIZE) {
            throw new Error(`too short input data ${inData.length} ${inOff}`);
        }

        if (outData.length - outOff < this.BLOCKSIZE) {
            throw new Error(`too short output buffer ${outData.length} / ${outOff}`);
        }

        if (this.mode === 'ENCRYPT') {
            return this.encryptBlock(inData, inOff, outData, outOff);
        }

        return this.decryptBlock(inData, inOff, outData, outOff);
    }

    encryptBlock(inData, inOff, outData, outOff) {
        this.pack(inData, inOff, this.block, 0, 16);

        for (let i = 0; i < this.rounds; ++i) {
            this.block[3] = this.ROR((this.block[2] ^ this.roundKeys[i][4]) + (this.block[3] ^ this.roundKeys[i][5]), 3);
            this.block[2] = this.ROR((this.block[1] ^ this.roundKeys[i][2]) + (this.block[2] ^ this.roundKeys[i][3]), 5);
            this.block[1] = this.ROL((this.block[0] ^ this.roundKeys[i][0]) + (this.block[1] ^ this.roundKeys[i][1]), 9);
            ++i;

            this.block[0] = this.ROR((this.block[3] ^ this.roundKeys[i][4]) + (this.block[0] ^ this.roundKeys[i][5]), 3);
            this.block[3] = this.ROR((this.block[2] ^ this.roundKeys[i][2]) + (this.block[3] ^ this.roundKeys[i][3]), 5);
            this.block[2] = this.ROL((this.block[1] ^ this.roundKeys[i][0]) + (this.block[2] ^ this.roundKeys[i][1]), 9);

            ++i;
            this.block[1] = this.ROR((this.block[0] ^ this.roundKeys[i][4]) + (this.block[1] ^ this.roundKeys[i][5]), 3);
            this.block[0] = this.ROR((this.block[3] ^ this.roundKeys[i][2]) + (this.block[0] ^ this.roundKeys[i][3]), 5);
            this.block[3] = this.ROL((this.block[2] ^ this.roundKeys[i][0]) + (this.block[3] ^ this.roundKeys[i][1]), 9);

            ++i;
            this.block[2] = this.ROR((this.block[1] ^ this.roundKeys[i][4]) + (this.block[2] ^ this.roundKeys[i][5]), 3);
            this.block[1] = this.ROR((this.block[0] ^ this.roundKeys[i][2]) + (this.block[1] ^ this.roundKeys[i][3]), 5);
            this.block[0] = this.ROL((this.block[3] ^ this.roundKeys[i][0]) + (this.block[0] ^ this.roundKeys[i][1]), 9);
        }

        this.unpack(this.block, 0, outData, outOff, 4);

        return this.BLOCKSIZE;
    }

    decryptBlock(inData, inOff, outData, outOff) {
        this.pack(inData, inOff, this.block, 0, 16);

        for (let i = this.rounds - 1; i >= 0; --i) {
            this.block[0] = (this.ROR(this.block[0], 9) - (this.block[3] ^ this.roundKeys[i][0])) ^ this.roundKeys[i][1];
            this.block[1] = (this.ROL(this.block[1], 5) - (this.block[0] ^ this.roundKeys[i][2])) ^ this.roundKeys[i][3];
            this.block[2] = (this.ROL(this.block[2], 3) - (this.block[1] ^ this.roundKeys[i][4])) ^ this.roundKeys[i][5];
            --i;

            this.block[3] = (this.ROR(this.block[3], 9) - (this.block[2] ^ this.roundKeys[i][0])) ^ this.roundKeys[i][1];
            this.block[0] = (this.ROL(this.block[0], 5) - (this.block[3] ^ this.roundKeys[i][2])) ^ this.roundKeys[i][3];
            this.block[1] = (this.ROL(this.block[1], 3) - (this.block[0] ^ this.roundKeys[i][4])) ^ this.roundKeys[i][5];
            --i;

            this.block[2] = (this.ROR(this.block[2], 9) - (this.block[1] ^ this.roundKeys[i][0])) ^ this.roundKeys[i][1];
            this.block[3] = (this.ROL(this.block[3], 5) - (this.block[2] ^ this.roundKeys[i][2])) ^ this.roundKeys[i][3];
            this.block[0] = (this.ROL(this.block[0], 3) - (this.block[3] ^ this.roundKeys[i][4])) ^ this.roundKeys[i][5];
            --i;

            this.block[1] = (this.ROR(this.block[1], 9) - (this.block[0] ^ this.roundKeys[i][0])) ^ this.roundKeys[i][1];
            this.block[2] = (this.ROL(this.block[2], 5) - (this.block[1] ^ this.roundKeys[i][2])) ^ this.roundKeys[i][3];
            this.block[3] = (this.ROL(this.block[3], 3) - (this.block[2] ^ this.roundKeys[i][4])) ^ this.roundKeys[i][5];
        }

        this.unpack(this.block, 0, outData, outOff, 4);

        return this.BLOCKSIZE;
    }

    pad(inData, inOff) {
        if (!inData) {
            throw new Error('inData should not be null');
        }

        if (inData.length < inOff) {
            throw new Error('inOff is greater than inData length');
        }

        const code = inData.length - inOff;
        inData.fill(code, inOff, inData.length);
    }

    unpad(inData) {
        if (!inData || inData.length < 1) {
            throw new Error('inData should not be null or empty');
        }

        if (inData.length % this.BLOCKSIZE !== 0) {
            throw new Error('Bad padding');
        }

        const count = inData[inData.length - 1] & 0xff;

        let isBadPadding = false;
        const lowerBound = inData.length - count;
        for (let i = inData.length - 1; i > lowerBound; --i) {
            if (inData[i] !== count) {
                isBadPadding = true;
            }
        }

        if (isBadPadding) {
            throw new Error('Bad Padding');
        }

        const outData = new Uint8Array(inData.length - count);
        outData.set(inData.subarray(0, inData.length - count));

        return outData;
    }

    ROL(state, num) {
        return (state << num) | (state >>> (32 - num));
    }

    ROR(state, num) {
        return (state >>> num) | (state << (32 - num));
    }

    pack(inData, inOff, outData, outOff, inLen) {
        if (!inData || !outData) {
            throw new Error('inData and outData should not be null');
        }

        if ((inLen & 3) !== 0) {
            throw new Error('length should be multiple of 4');
        }

        if (inData.length < inOff + inLen || outData.length < outOff + inLen / 4) {
            throw new Error('Array index out of bounds');
        }

        let outIdx = outOff;
        const endInIdx = inOff + inLen;
        for (let inIdx = inOff; inIdx < endInIdx; ++inIdx, ++outIdx) {
            outData[outIdx] = inData[inIdx] & 0xff;
            outData[outIdx] |= (inData[++inIdx] & 0xff) << 8;
            outData[outIdx] |= (inData[++inIdx] & 0xff) << 16;
            outData[outIdx] |= (inData[++inIdx] & 0xff) << 24;
        }
    }

    unpack(inData, inOff, outData, outOff, inLen) {
        if (!inData || !outData) {
            throw new Error('inData and outData should not be null');
        }

        if (inData.length < inOff + inLen || outData.length < outOff + inLen * 4) {
            throw new Error('Array index out of bounds');
        }

        let outIdx = outOff;
        const endInIdx = inOff + inLen;
        for (let inIdx = inOff; inIdx < endInIdx; ++inIdx, ++outIdx) {
            outData[outIdx] = inData[inIdx] & 0xff;
            outData[++outIdx] = (inData[inIdx] >>> 8) & 0xff;
            outData[++outIdx] = (inData[inIdx] >>> 16) & 0xff;
            outData[++outIdx] = (inData[inIdx] >>> 24) & 0xff;
        }
    }

}

//실행할수 있는 예시 코드
/* const encoder = new TextEncoder();

const key = encoder.encode('geomexLEA1234fjk');
const lea = new LEA(key);
const data = encoder.encode('강아지@!#$$1@!.gho홍길동.강아지@!#$$1@!.');
const encryptedData = lea.encrypt(data);
const decryptedData = lea.decrypt(encryptedData);

const decoder = new TextDecoder();
console.log(lea.base64Encode(encryptedData));
console.log(decoder.decode(encryptedData));
console.log(decoder.decode(decryptedData)); */
