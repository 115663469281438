/* eslint-disable */

var Arcfour = require('./prng4')

const SecureRandom = (function () {
    'use strict'

    var rng_state
    var rng_pool
    var rng_pptr
    var rng_psize = 256

    // Mix in a 32-bit integer into the pool
    function rng_seed_int(x) {
        rng_pool[rng_pptr++] ^= x & 255
        rng_pool[rng_pptr++] ^= (x >> 8) & 255
        rng_pool[rng_pptr++] ^= (x >> 16) & 255
        rng_pool[rng_pptr++] ^= (x >> 24) & 255
        if (rng_pptr >= rng_psize) rng_pptr -= rng_psize
    }

    // Mix in the current time (w/milliseconds) into the pool
    function rng_seed_time() {
        rng_seed_int(new Date().getTime())
    }

    // Initialize the pool with junk if needed.
    if (rng_pool == null) {
        rng_pool = new Array()
        rng_pptr = 0
        var t
        if (typeof window !== 'undefined' && navigator.appName == 'Netscape' && navigator.appVersion < '5' && window.crypto) {
            // Extract entropy (256 bits) from NS4 RNG if available
            var z = window.crypto.random(32)
            for (t = 0; t < z.length; ++t) { rng_pool[rng_pptr++] = z.charCodeAt(t) & 255 }
        }
        while (rng_pptr < rng_psize) { // extract some randomness from Math.random()
            t = Math.floor(65536 * Math.random())
            rng_pool[rng_pptr++] = t >>> 8
            rng_pool[rng_pptr++] = t & 255
        }
        rng_pptr = 0
        rng_seed_time()
        // rng_seed_int(window.screenX);
        // rng_seed_int(window.screenY);
    }

    function rng_get_byte() {
        if (rng_state == null) {
            rng_seed_time()
            rng_state = Arcfour // prng_newstate();
            rng_state.init(rng_pool)
            for (rng_pptr = 0; rng_pptr < rng_pool.length; ++rng_pptr) { rng_pool[rng_pptr] = 0 }
            rng_pptr = 0
            // rng_pool = null;
        }
        // TODO: allow reseeding after first request
        return rng_state.next()
    }

    function rng_get_bytes(ba) {
        var i
        for (i = 0; i < ba.length; ++i) ba[i] = rng_get_byte()
    }

    function SecureRandom() { }
    SecureRandom.prototype.nextBytes = rng_get_bytes

    return SecureRandom
})()

if (typeof module === 'object' && module.exports) {
    module.exports = SecureRandom
}