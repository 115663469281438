
/**
 * 검색 필터 / 전체 검색 등 시스템 상에서 가지고 있어야 할 정보
 * 
 */
const stateValue = {
  filter :  {t:''} , //검색 - table 휘발성 조건.
  searchParam : { } //검색인데 API param으로 적용 되는 것.
}
export default {
    //하나의 store안에서 모듈화되어 사용될 수 있음을 명시
    namespaced : true,
    // 실제로 취급해야하는 각각의 data(==data)
    state: () => (stateValue),
    getters: {
      getFilter(state) {
        return state.filter;
      },

      getSearchParam(state) {
        return state.searchParam;
      },
      
     
      getColumnsValue : (state) => (list) => {
        list.forEach((item)=> {
          for ( const f in state.filter ) {
              if ( item.id === f ) {
                  item.value = state.filter[item.id];
                  continue;
              }
            }
          });
        return list;
      },

    },
    
    //✅mutations를 통에서만 데이터를 변경할 수 있습니다.
    mutations: {
      
      initFilter(state) {
        state.filter = {};
        state.searchParam = {};
      },

      setFilter(state, param) {
        state.filter = param;
      },

      setSearchParam(state, param) {
        state.searchParam = {...param};
      }
    }, 

    actions: {

      /**
       *  필터 초기화
       */
      initFilter({ commit }, param) {

        commit('initFilter', param);
      },
      
      /**
       *  필터 저장
       */
      setFilter({ commit }, param) {

        commit('setFilter', param);
      },
      /**
       *  검색 필터 저장
       */
      setSearchParam({ commit }, param) {

        commit('setSearchParam', param);
      },
      
    }
    
  }