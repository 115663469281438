<template>
    <div class="gmx_textbox">
        <div class="texbox_container">
          <GmxLabel :title="title" :important="important"></GmxLabel>
          <div>
            <slot name="input"></slot>
          </div>
        </div>
        <div class="footer_content" >
          <slot name="footer"></slot>
          
        </div>
        <span v-if="error !== ''" class="error">{{ error }}</span>
    </div>
</template>

<script>
import { defineComponent, ref  } from 'vue';
import GmxLabel from './GmxLabel.vue';

export default defineComponent({
  name : 'GmxTextBox',
  components : {
    GmxLabel,
  },
  props : {
    id : {
        type: String,
        default : ''
    },
    title : {
        type: String,
        default : ''
    },
    hintMsg : {
        type: String,
        default : ''
    },
    type : {
        type: String,
        default : ''
    },
    valid : {
        type : Function,
        default : null
    },
    important: {
        type: Boolean,
        default: false
    },
    value : {
        type : String,

    }
  },

  setup(props, context) {
    let error = ref('');
    const { emit } = context;
    const gmxInput = ref(props.value);

    const onKeyDown = (event) => {
      emit('key-down', event, props.id);
    };

    const onKeyUp = (event) => {
      emit('key-up', event, props.id);
      if ( props.valid) {
        error.value = props.valid(props.id, event.target.value);
      } else {
        error.value = '';
      }
    };

    const onFocus = (event) => {
      emit('focus', event, props.id);
    }

    const getValue = () => {
      return gmxInput.value;
    }

    const setValue = (v) => {
      gmxInput.value = v;
    }

    return {
        setValue,
        getValue,
        gmxInput,
        error,
        onFocus,
        onKeyDown,
        onKeyUp
    }
  }

  
});
</script>

<style scoped lang="scss" >
.footer_content {
 padding-left:140px;
}
  .gmx_textbox {
    width: 100%;
    .texbox_container {
      display: flex;
      align-items: center;
      > div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        > ::v-deep .gmx_textbox {
          flex: 1 0 auto;
          width: 0;
          min-width: 0;
        }
      }
    }
    
    input {
      width: 100%;
      box-sizing: border-box;
      padding: 0 8px;
      border: 1px solid $gmx-box-br-color;
      border-radius: $border-radius1;
      font-size: $font-size2;
      &:focus {
        border: 1px solid $gmx-main-color;
      }
      &::placeholder {
        color: $gmx-box-sub-br-color;
        font-size: $font-size2;
      }
      &.error-active {
        border: 1px solid $alert-color1;
      }
      &[type="date"] {
        position: relative;
        box-sizing: content-box;
        &::-webkit-clear-button {
          display: none;
        }
        &::-webkit-inner-spin-button {
          display: none;
        }
        &::-webkit-calendar-picker-indicator {
          background-color: transparent;
          opacity: 0;
          z-index: 1;
        }
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 8px;
          transform: translateY(-50%);
          display: block;
          width: 16px;
          height: 16px;
          background-image: url(@/assets/img/icn_cal.png);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          z-index: 0;
        }
      }
    }
    .error {
      display: inline-block;
      margin-top: 6px;
      font-size: $font-size1;
      color: $alert-color1;
    }
  }
</style>