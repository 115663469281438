<template>
  <!-- 시자악  -->
  <div class="wrap">
    sdfsdfsdfsdfds
  </div>
</template>

<script>

</script>

<style lang="scss">

  .wrap{
    height:100%;
    width:100%;
    .header {
      height:60px;
      line-height: 60px;
      padding-left:20px;
      color:#fff;
      font-size:18px;
      background : var(--main-bg-color);
      display: flex;
      justify-content: space-between;
     
      .opt {
        margin-right:10px;
      }

      * {
        color:#fff;
      }
    }
  }
 
  //Lnb 스타일 이에요... 지우셔도 됩니당..
  .gmx_main.body {
    display: flex;
    height: calc(100% - 60px);
    .content {
        padding:20px;
        border-left : 1px solid var(--main-border-color)
      }
      .lnb {
        width: 300px;
        }
        
        ul li {
          padding:10px;
        }

        ul li:hover {
          background:#d1d1d1
        }

  }

</style>