/* eslint-disable */
var BigInteger = require('./jsbn')
var SecureRandom = require('./rng')

var rsa = (function () {

  function parseBigInt(str, r) {
    return new BigInteger(str, r)
  }

  // PKCS#1 (type 2, random) pad input string s to n bytes, and return a bigint
  function pkcs1pad2(s, n) {
    let buffer = new TextEncoder().encode(s);
    if (n < buffer.length + 11) {
      console.error('Message too long for RSA');
      return null;
    }
    let ba = new Array(n);
    let i = buffer.length - 1;
  
    while (i >= 0 && n > 0) {
      ba[--n] = buffer[i--];
    }
    ba[--n] = 0;
    let rng = new SecureRandom();
    let x = [0];
    while (n > 2) {
      x[0] = 0;
      while (x[0] === 0) rng.nextBytes(x);
      ba[--n] = x[0];
    }
    ba[--n] = 2;
    ba[--n] = 0;
  
    //console.log("Padded message:", ba);
    return new BigInteger(ba);
  }

  // "empty" RSA key constructor
  function RSAKey() {
    this.n = null;
    this.e = 0;
    this.d = null;
    this.p = null;
    this.q = null;
    this.dmp1 = null;
    this.dmq1 = null;
    this.coeff = null;
  }

  // Set the public key fields N and e from hex strings
  function RSASetPublic(N, E) {
    if (N != null && E != null && N.length > 0 && E.length > 0) {
      this.n = parseBigInt(N, 16);
      this.e = parseInt(E, 16);
    } else {
      console.log('Invalid RSA public key');
    }
  }

  // Perform raw public operation on "x": return x^e (mod n)
  function RSADoPublic(x) {
    return x.constructor === BigInteger ? x.modPowInt(this.e, this.n) : '';
  }

  // Return the PKCS#1 RSA encryption of "text" as an even-length hex string
  function RSAEncrypt(text) {
    var m = pkcs1pad2(text, (this.n.bitLength() + 7) >> 3);
    if (m == null) return null;
    var c = this.doPublic(m);
    if (c == null) return null;
    var h = c.toString(16);
   
    if ((h.length & 1) == 0) return h; else return '0' + h;
  }

  // protected
  RSAKey.prototype.doPublic = RSADoPublic;

  // public
  RSAKey.prototype.setPublic = RSASetPublic;
  RSAKey.prototype.encrypt = RSAEncrypt;

  return RSAKey;
})()

if (typeof module === 'object' && module.exports) {
  module.exports = rsa;
}