<template>
  <div class="gmx_selbox" :class="(localError !== '' ? 'error-active' : '') + ' ' + cl">
    <div class="selbox_container">
      <div class="title" @click="toggleOptions" 
      :class="(isOpen ? 'active' : '') + (isRead ? ' read' : '') + (computedSelectValue.getSelectLabel().label === '전체' ? ' empty' : '')">
          {{ computedSelectValue.getSelectLabel().label }}
      </div>
      <ul v-if="!isRead" :id="id" v-show="isOpen" class="items">
        <li v-if="items.length == 0" @click="computedSelectValue.changeOption({ id: 'first', label: computedSelectValue.getSelectLabel().label})">
            {{ computedSelectValue.getSelectLabel().label }}
        </li>
        <li class="item" v-for="item in items" :key="item.value" @click="computedSelectValue.changeOption(item)">
          <template v-if="props.modelValue == item.mgrSeq" >{{ item.label }}</template>
          <template v-else>{{ item.label }}</template>
        </li>
      </ul>
    </div>
  </div>
  <div v-if="localError && error !== ''" class="error">{{ error }}</div>
</template>
<script setup>
import { computed, defineEmits, defineProps, ref, onMounted, onBeforeUnmount, watch, defineExpose } from 'vue';

const props = defineProps({
  id: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  cl : {
    type: String
  },
  items: {
    type: Array,
    default: () => { }
  },
  valid: {
    type: Function,
    default: null
  },
  modelValue: {
    type: String,
    default: ''
  },
  type : {
    type : String,
    default : ''
  },
  error : {
    type : String,
    default : ''
  },
  callBack : {
    type : Function,
    default : ()=>{}
  },
});
const localError = ref(props.error);

onMounted(()=>{
  
  // 컴포넌트가 제거되기 전에 이벤트 리스너 제거
  onBeforeUnmount(() => {
    document.removeEventListener("click", absToggleOptions);
  });
});


watch(() => props.error, (newValue) => {

// 부모에서 변경된 error 값을 감지하면 localError를 업데이트
localError.value = newValue;
});

const emit = defineEmits(['change', 'update:modalValue']);

let isOpen = ref(false);
let selectedOption = ref({mgrSeq:props.modelValue, label:props.title});

const absToggleOptions = () => {
  isOpen.value = false;
  document.removeEventListener("click", absToggleOptions);
}
//임시 조치
//값이 전달되면 리스트에 해당되는 값과 매칭하여 셀렉트 되도록 수정.
//computed로 빼야함... 또 반응이 너무 느림..
const toggleOptions = () => {
  isOpen.value = !isOpen.value;
 
  if ( isOpen.value === true) {
    setTimeout(()=>{
      document.addEventListener("click", absToggleOptions);
    }, 500);
  } else {
    document.removeEventListener("click", absToggleOptions);
  }

};
const isRead = props.type === 'read';
const computedSelectValue = computed({
  get() {

    if (props.items != null || props.items != undefined) {
      props.items.forEach(function (item) {
        if (item.label == props.modelValue) {
          selectedOption.value.mgrSeq = props.modelValue;
        }
      });
    }
    
    //selectedOption.value.mgrSeq = props.modelValue;

    const changeOption = (item) => {

      if ( isRead ) return;
      selectedOption.value.label = item.label;
      isOpen.value = false;
      emit("change", props.id, item.value);
      emit('update:modelValue', item.value);

      localError.value = '';
      props.callBack(props.id, item.value);
    }

    const getSelectLabel = () => {
      props.items.forEach(function (item) {
        if (item.value == props.modelValue) {
          selectedOption.value.label =item.label;
          selectedOption.value.id =item.value;
          
          //selectedItem = selectedOption.value;
        }
      });
      const selectedItem = selectedOption.value;

      return selectedItem;
    }
    return {
      getSelectLabel,
      changeOption
    }
  }
})

const getLabel = () => {
  
  return selectedOption.value.label;
}
defineExpose({
  getLabel
})

</script>

<style scoped lang="scss">
.gmx_selbox {
  display: flex;
  align-items: center;
  width: 100%;
  &.error-active {
        border: 1px solid $alert-color1 !important;
      }
  .col {
    flex: none;
    width: 130px;
    margin-right: 8px;
    font-size: $font-size2;

    .important {
      color: $alert-color1;
      font-size: $font-size1;
    }
  }

  .selbox_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 34px;
    box-sizing: border-box;
    padding: 0 8px;
    border: 1px solid $gmx-box-br-color;
    border-radius: $border-radius1;
    cursor: pointer;

    .title:not(.read)  {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      font-size: $font-size2;

      &:after{
        content: "";
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url(@/assets/img/icn_arrow_down.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transform: rotate(0deg);
        transition: transform 0.3s;
      }


      &.active {
        &:after {
          transform: rotate(-180deg);
        }
      }
    }

    .read {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        font-size: $font-size2;
        cursor:default
      }
    .items {
      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      z-index: 99;
      width: 100%;
      padding: 8px 0;
      background-color: $gmx-theme-color;
      border: 1px solid $gmx-box-br-color;
      border-radius: $border-radius1;
      overflow: hidden;

      li {
        display: flex;
        align-items: center;
        width: 100%;
        height: 2.5em;
        box-sizing: border-box;
        padding: 0 12px;
        background-color: $gmx-theme-color;
        font-size: $font-size2;
        font-family: $font-type0;
        cursor: pointer;

        &:hover {
          background-color: $gmx-box-br-color;
        }
      }

    }
  }

  &.search {
    
    .selbox_container {
      
      height: 29px;
      border:1px solid #DCDCDC;
      border-radius: 5px;
      margin-top: 5px;
      background: #fff;

      .empty {
        
        color:$gmx-font-sub-color2
      }
      
    }
  }
}
.error {
    display: inline-block;
    margin-top: 6px;
    font-size: $font-size2;
    color: $alert-color1;
  }
</style>