<template>
    <span v-if="title != ''" class="gmx_label" :class="type">
      {{title}}
      <span v-if="important" class="important">*</span>
    </span>
</template>

<script>
import { defineComponent, ref  } from 'vue';

export default defineComponent({
  name : 'GmxTextBox',
  props : {
    title : {
        type: String,
        default : ''
    },
    important: {
        type: Boolean,
        default: false
    },
    type : {
      type : String,
    }
  },

  setup(props, context) {
    let error = ref('');
    const { emit } = context;
    const gmxInput = ref(props.value);

    const onKeyDown = (event) => {
      emit('key-down', event, props.id);
    };

    const onKeyUp = (event) => {
      emit('key-up', event, props.id);
      if ( props.valid) {
        error.value = props.valid(props.id, event.target.value);
      } else {
        error.value = '';
      }
    };

    const onFocus = (event) => {
      emit('focus', event, props.id);
    }

    const getValue = () => {
      return gmxInput.value;
    }

    const setValue = (v) => {
      gmxInput.value = v;
    }

    return {
        setValue,
        getValue,
        gmxInput,
        error,
        onFocus,
        onKeyDown,
        onKeyUp
    }
  }

  
});
</script>

<style scoped lang="scss" >

    .gmx_label {
      flex: none;
      width: 130px;
      margin-right: 8px;
      font-size: $font-size2;
      .important {
        color: $alert-color1;
        font-size: $font-size1;
      }
       
      &.info {
        &::before {
          content : url(@/assets/img/icn_info.svg);
          vertical-align: text-top;
          margin-right:5px;
        }
        color : $state-color4;
      }
      &.warn {
        color : $state-color2;
      }
    }
</style>