<template>
    <span :class="getServiceClass()" class="result_type"></span>
</template>

<script setup>
import { defineProps } from 'vue';
const props = defineProps({
    type : String
});

/**
 * 타입 별로 표출한다.
 */
const getServiceClass = () => {
    let cl = '';

    switch ( props.type ) {
        case '프로젝트' :
            cl = 'type1';
            break;
        case '영업' :
        case '영업현황' :
            cl = 'type2';
            break;  
        case '수주계약' :
            cl = 'type3';
            break;
        case '외주계약' :
            cl = 'type4';
            break;
    }

    return cl;
}

</script>
<style scoped lang="scss">
     .result_type {
            // display: flex;
            padding:8px;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 26px;
            border: 1px solid $gmx-box-br-color;
            border-radius: $border-radius2;
            font-size: $font-size1;
            line-height : 10px;

            &::before {
                content: "기타";
                color: $gmx-box-sub-br-color;
            }

            //프로젝트
            &.type1 {
                background-color: rgba($state-color4, 0.1);
                border-color: rgba($state-color4, 0.1);

                &::before {
                    content: "프로젝트";
                    color: $state-color4;
                }
            }

            //영업현황
            &.type2 {
                background-color: rgba($state-color5, 0.1);
                border-color: rgba($state-color5, 0.1);

                &::before {
                    content: "영업현황";
                    color: $state-color5;
                }
            }

            //수주계약
            &.type3 {
                background-color: rgba($state-color3, 0.1);
                border-color: rgba($state-color3, 0.1);

                &::before {
                    content: "수주계약";
                    color: $state-color3;
                }
            }

            //외주계약
            &.type4 {
                background-color: rgba($state-color2, 0.1);
                border-color: rgba($state-color2, 0.1);

                &::before {
                    content: "외주계약";
                    color: $state-color2;
                }
            }
        }
</style>