
import RequsetApI from '@/js/request/requsetApI.js';
import AuthUtil from './unit/AuthUtil.js';
import {getLocalItem} from '@/js/utils/StorageUtil';
/**
 * 가독성 목적으로.. 사용했습니다.
 * 절대 stateValue를 직접 변경하지말아주세요.
 */


//갱신주기(초)
const _USER_INFO_SYNC_TIME = 10;

export default {
    //하나의 store안에서 모듈화되어 사용될 수 있음을 명시
    namespaced : true,
    // 실제로 취급해야하는 각각의 data(==data)
    state: {
      auth : null, //권한
      user : null,//사용자 정보
      token : ''
    },
    
    // 계산된 상태를 만들어내는 개념(==computed)
    getters: {
      getAuthList(state) {
        return state.auth;
      },

      getUserInfo(state) {
        return state.user;
      },

      getToken(state) {
        return state.token;
      }
    },
    

    //✅mutations를 통에서만 데이터를 변경할 수 있습니다.
    mutations: {
      initSyncUserInfo(state) {

        RequsetApI.findUserInfo({userId:getLocalItem('eUserId')}, (res1) => {
          if ( res1.data.results.length > 0 ) {
            state.user = res1.data.results[0];
            RequsetApI.selectAuthJoin({grpMgrSeq:res1.data.results[0].authGrpSeq}, (res) => {
              state.auth = res.data.results;
            });
          }
        });
      },

      setAuthList(state, data) {
        state.auth = data;
      },

      setUserInfo(state, data) {
        state.user = data;
      },

      setToken(state, data) {
        state.token = data;
      }
    }, 

    //actions의 함수들은 비동기로만 동작
    //✅async, await를 붙이지 않아도 비동기로만동작한다.
    actions: {
      initSyncUserInfo({ commit }, payload) {
        commit('initSyncUserInfo', payload);
        setInterval(() => {
          commit('initSyncUserInfo', payload);
        }, _USER_INFO_SYNC_TIME * 1000 ); // 1초마다 상태를 증가시키는 예제

      
      },

      async chkUrlAuth(context, payload) {
        // state.auth를 이용한 권한 체크
        const isAuthorized = await AuthUtil.chkUrlAsyncOrg(context.state.auth, payload);
     
        return isAuthorized;
      },

      setAuthList({ commit }, payload) {
        commit('setAuthList', payload);
      },
      
      setUserInfo({commit}, payload) {
        commit('setUserInfo', payload);
      },
     
      setToken({commit}, payload) {
        commit('setToken', payload);
      }
    }
    
  }