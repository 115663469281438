<template>
    <div class="gmx_textbox">
        <div class="texbox_container" @click="handleSearchInputClick">
            <input :id="id" class="textbox" :value="computedValue" :max="type==='date' || type ==='month' ? '9999-12-31' : max" :type='type' :readonly="type==='search' || type === 'read' ? true : false" :placeholder='hintMsg' :[state]="true"
            @input="updateValue" :class="customClass()" />
             <!--검색용 버튼을 만들자! (props.type === search 인 경우) -->
             <GmxButton v-if="type === 'search'" cl="c" class="btn_search"></GmxButton>
            </div>
            <div v-if="localError !== ''" class="error">{{ error }}</div>
    </div>
</template>

<script setup>

import { defineProps, defineEmits, computed, ref, watch} from 'vue';
import GmxButton from '../button/GmxButton.vue';
import DateUtil from '@/js/utils/DateUtil';

const props = defineProps({
    id : {
        type: String,
        default : ''
    },
   
    hintMsg : {
        type: String,
        default : ''
    },
    type : {
        type: String,
        default : ''
    },
    cl : {
        type: String,
        default : ''
    },
    valid : {
        type : Function,
        default : null
    },
    modelValue : {
        validator: function (value) {

          // 문자열 또는 숫자인지 검사
          return typeof value === 'string' || typeof value === 'number';
        },
        default : ''
    },
    state: {
      type: String,
    },
    format : {
      type : Function,
      default : null
    },
    max : {
      type : Number
    },
    error : {
      type : String,

      default : ''
    },
    callBack : {
      type : Function,
      default : null
    }
});

const customClass = () => {
  let cl = (props.localError && props.localError !== '' ? 'error-active' : '') + ' ' + props.cl;
  if (  props.type === 'date' &&  computedValue.value != '' ) cl += ' has_value'; 
  return cl;
}
//const gmxInput = ref(props.value);

const emit = defineEmits(['focus', 'input', 'search', 'error']);
const localError = ref(props.error);
const handleSearchInputClick = (event) => {
  if ( props.type === 'search' ) {
    emit('search', event.target.value);
  }
}

watch(() => props.error, (newValue) => {

  // 부모에서 변경된 error 값을 감지하면 localError를 업데이트
  localError.value = newValue;
});

const updateValue = (event) => {

  if ( props.type === 'amount') {
    const validInput = event.target.value.replaceAll(/[^0-9]/g, '');
    // 정수형으로 변환하고, 빈 문자열은 0으로 처리합니다.
    const intValue = parseInt(validInput, 10) || 0;
    event.target.value = intValue;
  }

  if ( props.type === 'number') {
    const validInput = event.target.value.replaceAll(/[^0-9]/g, '');
    // 정수형으로 변환하고, 빈 문자열은 0으로 처리합니다.
    let intValue = parseInt(validInput, 10) || 0;

    if (intValue > props.max ) intValue = props.max;
    event.target.value = intValue;
  }

  if ( props.type === 'date') {
    event.target.value = event.target.value.trim();
  }
  localError.value = '';
  emit('update:modelValue', event.target.value); // 이벤트 발생하여 값을 부모 컴포넌트로 전달
  
  if( props.callBack) {
    
    props.callBack(props.id,  event.target.value);
  }
}


const computedValue = computed({
  get: () => {
    if ( props.type === 'amount' ) {
      if (!props.modelValue || props.modelValue === '') return 0;


      const payAmount = !isNaN(props.modelValue) ? (props.modelValue) : props.modelValue.replaceAll(",", "");
   
      return Number(payAmount).toLocaleString();
    } 
  
    if ( props.type === 'date' && props.modelValue && props.modelValue.indexOf('-') == -1) {
      // 정수형으로 변환하고, 빈 문자열은 0으로 처리합니다.
      const intValue = DateUtil.convertDateData(props.modelValue);
      return intValue;
    }

    return props.modelValue;
  },
});

</script>

<style scoped lang="scss" >

  .gmx_textbox {
    width: 100%;

    .texbox_container {
      position: relative;
      display: flex;
      align-items: center;
      .btn_search {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        background-image: url(@/assets/img/icn_search.svg);
        background-size: cover;
        transition: 0s;
      }
      &:hover {
        .btn_search {
          filter: invert(40%) sepia(72%) saturate(4838%) hue-rotate(197deg) brightness(106%) contrast(105%);
        }
      }
    }
    .col {
      flex: none;
      width: 130px;
      margin-right: 8px;
      font-size: $font-size2;
      .important {
        color: $alert-color1;
        font-size: $font-size1;
      }
    }
    input {
      width: 100%;
      height: 34px;
      box-sizing: border-box;
      padding: 0 8px;
      border: 1px solid $gmx-box-br-color;
      border-radius: $border-radius1;
      font-size: $font-size2;
      &:focus {
        border: 1px solid $gmx-main-color;
      }
      &::placeholder {
        color: $gmx-box-sub-br-color;
        font-size: $font-size2;
      }
      &:hover {
        border: 1px solid $gmx-box-br-color;
      }
      &:disabled {
        background-color: $gmx-box-bg-color;
        border: 1px solid $gmx-box-br-color;
      }
      &.error-active {
        border: 1px solid $alert-color1 !important;
      }
      &[type="date"] {
        position: relative;
        &::-webkit-clear-button {
          display: none;
        }
        &::-webkit-inner-spin-button {
          display: none;
        }
        &::-webkit-datetime-edit {color: #e4e4e4}
        &.has_value::-webkit-datetime-edit {color: #000}

        &::-webkit-calendar-picker-indicator {
          margin-left: -15px;
          background-color: transparent;
          opacity: 0;
          z-index: 1;
        }
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 6px;
          transform: translateY(-50%);
          display: block;
          width: 16px;
          height: 16px;
          background-image: url(@/assets/img/icn_cal.png);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          z-index: 0;
        }


      }

      &[type="search"] {
        cursor: pointer;
        caret-color: transparent; /* 커서를 투명하게 설정하여 숨김 */
        border: 1px solid $gmx-box-br-color;
        &:hover {
          border: 1px solid $gmx-box-br-color;
        }
      }
    }
    .error {
      display: inline-block;
      margin-top: 6px;
      font-size: $font-size2;
      color: $alert-color1;
    }
    .search {
      
        
        height: 29px;
        border:1px solid #DCDCDC;
        border-radius: 5px;
        margin-top: 5px;
        background: #fff;

        &.empty {
          color:$gmx-font-sub-color2
        }
      }
    
  }
</style>