<!-- 패널 컴포넌트..!
    1. Tip 표출 기능 추가
-->
<template>
    <div :class="(isScroll === true ? 'gmx_scroll '+cl :''+cl)" class="gmx_container">
        <slot></slot>
    </div>
</template>


<script>
import { defineComponent } from 'vue';
export default  defineComponent ({
    props:{
        isScroll : {
            type: Boolean,
            default : false
        },
        cl : {
            type:String,
            default : ''
        }
    },
});

</script>

<style scoped lang="scss"> /* 이 파일에만 적용되는 스타일 */
    .gmx_container {
        box-sizing: border-box;
        padding: 16px 30px;
        background-color: $gmx-theme-color;
        border: 1px solid $gmx-box-br-color;

        +.gmx_container {
            margin-top: 20px;
        } 
    }
    
    .gmx_scroll, .gmx_scroll.gmx_container:hover  {
        border: none;
        overflow: auto;
        margin:0;
        padding:0;
    }

  
    .gmx_scroll::-webkit-scrollbar-thumb {
        height: 30%; /* 스크롤바의 길이 */
      
    }

 
</style>

