const localStorage = window.localStorage;

/**
 * 
 * localstorage 조회
 * 
 * @param {*} k 
 */
export const getLocalItem = (k) => {
    return localStorage.getItem(k);  
};

/**
    localstorage 추가
 * @param {d} k 
 * @param {*} v 
 * @returns 
 */
export const setLocalItem = (k, v) => {
    localStorage.setItem(k, v);
};

/**
 * localstorage 초기화

 * @returns 
 */
export const init = () => {
    localStorage.clear();
};

/**
 * localstorage 제거
 *  * @param {d} k 
 * @returns 
 */
export const removeLocalItem = (k) => {
    localStorage.removeItem(k);
};

