export default {


    /**
     * 날짜 타입을 변경한다...
     *  - 코드 스
     * @param {*} str
     * @param isTime 일시까지 표출.
     * @returns
     */
    convertPayData: ( amount ) => {
        if (!amount ) return 0;
   
        const formatter = new Intl.NumberFormat('ko-KR', {
            style: 'decimal',
            currency: 'KRW',
        });
    
        return formatter.format(amount);

    },

    /**
     * 금액을 "억" "만원" 단위로 표출한다.
     * 
     * @param {*} amount 
     * @returns 
     */
    convertAmountKeyword : (amount) => {
        if ( amount == 0 ) return '-';
        // '억' 단위 계산
        const displayAmountInHundredMillion = 0;

        // '만 원' 단위 계산
        const displayAmountInTenThousand = Math.floor((Math.abs(amount) ) / 1000000);

        // 부호 처리
        const sign = (amount < 0) ? "-" : "";

        let total = '';

        if ( displayAmountInHundredMillion > 0 ) total += displayAmountInHundredMillion + '억'; //지금은 안씀..
        if ( displayAmountInTenThousand > 0 ) total += displayAmountInTenThousand + '';

        return ( total === '' ? '-' : sign + Number(total).toLocaleString());
    },

    /**
     * 퍼센트 값을 반환 한다.
     * 
     * @param {*} current 
     * @param {*} target 
     * @returns 
     */
    getPercent : (current, target, isOnlyNum) => {
        if ( !current || !target || current == 0 || target == 0  ) return 0+( !isOnlyNum ? '%' : '' );
        return Math.round(Number(current) / Number(target) * 100) + ( !isOnlyNum ? '%' : '' )
    },

    /**
     * 금액으로 표출되는 값을 반환한다.
     * @param {*} amount 
     * @returns 
     */
    getAmount : (amount) => { 
        if (!amount || amount == 0 ) return 0;
        return Number(amount).toLocaleString();
    },

    isEmpty : (str) => {
        return ( str == null && str == ''); 
    },

    /**
     * 바이트를 KB또는 MB 단위로 컨버팅한다.
     * 
     * @returns 
     */
    convertFileSize : (fileSize) => {
        if (fileSize < 1024) {
            return `${fileSize} Bytes`;
        } else if (fileSize < 1024 * 1024) {
            return `${(fileSize / 1024).toFixed(2)} KB`;
        } else {
            return `${(fileSize / (1024 * 1024)).toFixed(2)} MB`;
        }
    }

    
}