/**
 * 모든 코드의 대한 관리는 해당 스크립트에서 하시죠..
 *  - 대문자 스네이크 기법을 원칙으로 합니다.
 */


export default {

    //초기 페이지 설정.
    SYSTEM_NAME : 'ezone',
    INIT_PAGE : '/ezone/report/cont',
    LOGIN_PAGE : '/ezone/login',
    TOKEN_NAME : 'access_token',
    TOKEN_RF_NAME : 'refresh_token',
    
    //프로젝트 상태 관리
    PROJECT_STATE_STAY : '1', //대기
    PROJECT_STATE_ING : '2', //진행 중
    PROJECT_STATE_END : '3', // 완료
    
    MSG_STATE_ERROR : '0', //오류
    MSG_STATE_WRAN : '1', //경고
    MSG_STATE_INFO : '2', //정보
    MSG_STATE_OK : '3', //성공

    API_FAIL : 'fail', //api 요청 실패 flag
    API_OK : 'ok', //api 요청 실패 flag

    API_CODE_OK : 200, //api 요청 실패 flag

    COL_COLTYPE_CD_PAY : 'p', //테이블 표출 시 컬럼 타입 금액
    COL_COLTYPE_CD_PAY_100 : 'pay', //테이블 표출 시 컬럼 타입 금액 ( 단위 : 백만원 )
    COL_COLTYPE_CD_PAY_SALE : 'spay', //테이블 표출 시 컬럼 타입 금액 ( 단위 : 백만원 )
    COL_COLTYPE_CD_PAY_DISABLED : 'payd', //테이블 표출 시 컬럼 타입 금액 ( 단위 : 백만원 )
    COL_COLTYPE_CD_PAY_EDIT : 'paye', //테이블 표출 시 컬럼 타입 금액 ( 단위 : 백만원 )
    COL_COLTYPE_CD_DATE : 'd', //테이블 표출 시 컬럼 타입 날짜
    COL_COLTYPE_CD_TIME : 't', //테이블 표출 시 컬럼 타입 날짜
    COL_COLTYPE_CD_STATE : 'st', //테이블 표출 시 컬럼 타입 상태
    COL_COLTYPE_CD_STATE_NOGUIDE : 'stg', //테이블 표출 시 컬럼 타입 상태
    COL_COLTYPE_CD_PERCENT : 'per', //테이블 표출 시 컬럼 타입 상태
    COL_COLTYPE_CD_BTN : 'btn', //테이블 td안에 들어가는 버튼
    
    COL_COLTYPE_CD_STATE_UPD : 'stu', //테이블 표출 시 컬럼 타입 상태 수정 가능..
    COL_COLTYPE_CD_KEY : 'k', //테이블 표출 시 리드온리..!
    COL_COLTYPE_CD_SELECT : 'sb', //테이블 표출 시 셀렉트박스..!
    COL_COLTYPE_CD_SELECT_ORGZ : 'ob', //테이블 표출 시 셀렉트박스..!
    COL_COLTYPE_CD_PW : 'pw', //테이블 표출 시 암호..!
    COL_COLTYPE_CD_ICON : 'i', //테이블 표출 등급?..!
    COL_COLTYPE_CD_TAG_ICON : 'tag', //테이블 표출 등급?..!
    COL_COLTYPE_CD_SERVICE_NAME : 'snm', //테이블 표출 등급?..!
    COL_COLTYPE_CD_LVL : 'lvl', //테이블 표출 등급?..!
    COL_COLTYPE_CD_GRP : 'grp', //테이블 표출 등급?..!


    //**************API요청에 사용되는 CODE */
    SERVICE_CODE_SALE : 'CtSale', //영업 
    SERVICE_CODE_SALE_HISTORY : 'CtSaleHist', //영업 
    SERVICE_CODE_SALE_NOTI : 'CtSaleNoti', //영업 
    SERVICE_CODE_PROJ : 'CtProj', //프로젝트
    SERVICE_CODE_PROJ_SUB : 'CtProjSub', //프로젝트
    SERVICE_CODE_PROJ_HISTORY : 'CtProjHist', //프로젝트
    SERVICE_CODE_ORDERS : 'CtOrders', //수주
    SERVICE_CODE_ORDERS_SUB : 'CtOrdersSub', //수주입금
    SERVICE_CODE_OUTS : 'CtOuts', //외주
    SERVICE_CODE_OUTS_SUB : 'CtOutsSub', //외주입금
    SERVICE_CODE_CONT : 'CtCont', //프로젝트
    SERVICE_CODE_CONT_HISTORY : 'CtContHist', //프로젝트
    SERVICE_CODE_CLIENT : 'MtClient', //발주처
    SERVICE_CODE_ACCINFO : 'MtAcc', //거래처
    SERVICE_CODE_USER : 'MtUsrDesc', //사용자
    SERVICE_CODE_HISTORY : 'MtAccessLog', //활동 이력
    SERVICE_CODE_SOLUTION : 'MtSol', //솔루션
    SERVICE_CODE_FILE : 'MtFileList', //파일
    SERVICE_CODE_TARGET : 'MtTarget', //목표매출
    
    //************** 영업상태 CODE */
    SALE_STATE_0 : '00', //기회
    SALE_STATE_1 : '01', //고려
    SALE_STATE_2 : '02', //적극
    SALE_STATE_3 : '03', //확실
    SALE_STATE_4 : '04', //수주
    SALE_STATE_5 : '05', //실패

    STORE_COMMON_TOAST : 'gmxCommon/showToast',
    STORE_COMMON_ALERT : 'gmxCommon/showAlert',
    STORE_COMMON_CONFIRM : 'gmxCommon/showConfirm',

}


