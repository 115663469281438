import { createApp } from 'vue'
import App from './App.vue'

import store from '@/js/store';

import axios from 'axios';
import router from './router';
import VueCookies from 'vue-cookies';
import PrimeVue from 'primevue/config';
import  '@/assets/css/reset.css'; //
import 'primevue/resources/themes/saga-blue/theme.css';       //theme
import 'primevue/resources/primevue.min.css';                 //core css
import 'primeicons/primeicons.css';            
import ChartDataLabels from 'chartjs-plugin-datalabels';
//import AuthUtil from './js/store/modules/unit/AuthUtil';
import VueDatePicker from '@vuepic/vue-datepicker';
import "vue3-openlayers/styles.css";
import OpenLayersMap from "vue3-openlayers";

async function initializeApp() {
    //AuthUtil.chkUrl()
    
    //await AuthUtil.chkUrl(null, '').then(()=>{
        //icons

        createApp(App)
        .use(ChartDataLabels)
        .use(OpenLayersMap /*, options */)
        .use(router)
        .use(VueCookies)
        .use(store)
        .use(PrimeVue)
        .use(VueDatePicker, {  
            locales: {
              ko: {
                days: ['일', '월', '화', '수', '목', '금', ''],
                months: [
                  '1월', '2월', '3월', '4월', '5월', '6월',
                  '7월', '8월', '9월', '10월', '11월', '12월',
                ],
              },
            },
          })

        .mount('#app');

 //   });
    
  
      
}
if ( process.env.VUE_APP_GUIDE == 'true' ) {
  createApp(App)
  .mount('#app');
} else {
  
  initializeApp();
}

axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

