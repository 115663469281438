import GmxGlobal from '@/js/global';
import {getLocalItem} from '@/js/utils/StorageUtil';
import RequsetApI from '@/js/request/requsetApI';

export default {

    /**
     * 영업 현황 조회 시 공개 여부에 대한 로직 상세보기 접근 시 접근 처리.
     * 
     * @param {*} to 
     * @param {*} callBack 
     */
    chkSaleAddPage : (to, callBack) => {

        const saleCdParam = to.query.id;

        RequsetApI.selectCommonInfo(GmxGlobal.SERVICE_CODE_SALE, { saleCd: saleCdParam }, (res) => {
            if ( res.data.results.length > 0 ) {
                const data = res.data.results[0];
                const constId = data.constId ? data.constId.trim() : '';
                const visCd = data.visCd;
                const loginUsrId = getLocalItem('eUserId');
                
                RequsetApI.findUserInfo({ userId: loginUsrId }, (res1) => {
                    const userInfo = res1.data.results[0];
                    const loginUsrGrp = userInfo.authGrpSeq;
            
                    if (visCd === '0') { // 작성자
                    return (loginUsrId === constId) ? callBack() : callBack('/ezone/main/sales');
                    } else if (visCd === '1') { // 전체공개
                    return callBack();
                    } else { // 영업부만(영업담당자(3), 영업지원(4))
                    return (loginUsrGrp == 3 || loginUsrGrp == 4) ? callBack() : callBack('/ezone/main/sales');
                    }
                });
            } else {
                callBack();
            }
            
        });
    },
}