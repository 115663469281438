import { createStore } from 'vuex';
import systemInfo from './modules/systemInfoStore';
import gmxCommon from './modules/gmxCommonStore';
import gmxMain from './modules/gmxMainStore';

const store = createStore({
  modules: {
    systemInfo,
    gmxCommon,
    gmxMain
  }
})

export default store;
