<template>
    <div :class="classRef">

       <span  v-for="(option,index) in options" :key="option.value">
           <label>
             <input type="radio" :name="nameGroup" :checked="(modelValue ? modelValue === option.value : index===0)" :value="option.value" @input="updateValue" :disabled="isRead" >
             {{ option.label }}
           </label>
       </span>
    </div>
</template>
  
<script setup>
import {  defineEmits, defineProps, ref } from 'vue'

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  modelValue: {
    required: true
  },
  class : {
    type:String,

  },
  type : {
    type : String,
    default: ''
  }
});

const isRead = props.type === 'read';
const nameGroup = Date.now();
const classRef = ref(props.class);

const emit = defineEmits(['update:modelValue']);

const updateValue = (event) => {

  emit('update:modelValue', event.target.value); // 이벤트 발생하여 값을 부모 컴포넌트로 전달
  //valid 체크

}
</script>

<style lang="scss" scoped>
    span {
        margin : 0 10px;
        cursor: pointer;

        &:first-child {
            margin-left:0;
        }

    }
</style>