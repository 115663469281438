import { createRouter, createWebHistory } from 'vue-router'
import ezoneRouter from './ezoneRouter';
import guideRouter from './guideRouter';
import RequsetApI from '@/js/request/requsetApI';
//import GmxGlobal from '@/js/global.js';


const routes = [...ezoneRouter, ...guideRouter];
import store from '@/js/store'; // Vuex store를 가져옴
import AuthUtil from '@/js/store/modules/unit/AuthUtil';
//import GmxUtil from '@/js/utils/GmxUtil';
//import GmxUtil from '@/js/utils/GmxUtil';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});


const selectSaveLogChk = (to, from, navigationEntries) => {
  
  let chkResult = false;

  if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
     chkResult = false;
  } else {
    if(from.fullPath.indexOf('ezone') === -1 && to.fullPath.indexOf('ezone') === 1){
      chkResult = true;
    }
  } 

  return chkResult;  
}

router.beforeEach(async (to, from, next) => {
  //전체검색
  if (to.path === '/ezone/search/totalSearch') {
    next();
    return;
  }
  //권한 체크
  // 인증 로직을 수행하여 접근 허용 여부를 체
  if (to.fullPath.indexOf('/login') != -1 ) {
    if ( to.fullPath.indexOf('/join')  != -1 || to.fullPath.indexOf('/find')  != -1  ) {
     
      next();
   
      return;
    }
    next();
    // RequsetApI.getToken().then(  (res) => {
    //   if ( GmxUtil.responseChk(res.status) ) {
    //     alert(res.status);
    //      AuthUtil.initFirstPageAsync(next);
    //   } else {
    //     next();
    //   }
    // });

    return;
  } else {
    
    //url로 접근시 영업현황 공개여부에 따라 표출하기.
    if ( to.meta.isMenu && from.fullPath.indexOf(to.fullPath) === -1 ) {
      store.dispatch('gmxMain/initFilter');
    }

    if ( to.fullPath.indexOf('Add') != -1 ) next();

    if ( !await AuthUtil.chkUrlAsyncOrg(to.path) ) {
       await AuthUtil.initFirstPageAsync(next); 

       return;
    } 
    /**
        * URL로 접근시에만 로그 저장 / 새로고침은 저장하지 않음
        */
    const navigationEntries = performance.getEntriesByType('navigation');
    if(selectSaveLogChk(to, from, navigationEntries)){
      RequsetApI.addAccessLog({ authData : to.fullPath});
    }
    
    next();

  }
 // }
});


export default router
