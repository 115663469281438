import RSA from './rsa';
import LEA from './lea';
/**
 * AES 256 암호화 알고리즘 사용.
 * 
 */
//const KEY = process.env.AES_KEY;

export const EncryptUtil = {

    encryptLEA: (data, key) => {
        // Tea 알고리즘을 사용하여 데이터를 암호화
        const encoder = new TextEncoder();
        key = encoder.encode(key);

        const lea = new LEA(key);
        const encryptedData = lea.encrypt(encoder.encode(data));
        
        return lea.base64Encode(encryptedData);
    },

    decryptLEA: (data, key) => {
        // Tea 알고리즘을 사용하여 데이터를 복호화
        const encoder = new TextEncoder();
        key = encoder.encode(key);

        const lea = new LEA(key);

        const decoder = new TextDecoder();
        const decryptedData = lea.decrypt(lea.base64Decode(data));

        return decoder.decode(decryptedData);
    },

    encryptRSA: (data, publicKey, modules) => {
        const rsa = new RSA();
		rsa.setPublic(modules, publicKey);
		return rsa.encrypt(data);

            // 데이터 암호화
       //return RSAKey.encrypt(data);
    },

    // decryptRSA: (data, privateKey) => {
    //     // 개인 키를 사용하여 데이터를 복호화
    //     const key = new NodeRSA(privateKey);
    //     return key.decrypt(data, 'utf8');
    // }
}

