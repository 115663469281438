import axios from "axios";

import DateUtil from "../utils/DateUtil";
import { getLocalItem, removeLocalItem } from '@/js/utils/StorageUtil';
//import GmxGlobal from "../global";
import GmxRequest from './unit/GmxRequest';
import {EncryptUtil} from '@/js/utils/crypto/EncryptUtil';
import { getCookie } from "../utils/CookieUtil";

/**
 * 모든 axios 요청에 대한 선언입니다.
 *
 *  - 개발 진행 단계에서는 파일 분할 및 객체 분할 계획 없습니다.
 *  - 추가 필요할 시 간단한 주석 정도만 부탁드립니다.
 *  - 필요 파라미터는 Back-end에서 확인해주시거나 스웨거를 통하여 확인 부탁드립니다.
 *
 * @owner 안형준
 *
 */
const API_CONTEXT_API = "/esupport-api";
const API_CONTEXT_SEARCH_API = "/searchSvc";
const API_ADDR_USER = "common/mtUsrDesc";
const API_ADDR_ORGZ = "/common/mtOrgzDesc";
const API_ADDR_UGRP = "/common/mtAuthGrp";
const API_ADDR_ACCLVL = "/common/mtAccLvl";
const API_ADDR_AUTHJOIN = "/common/mtAuthJoin";
const API_ADDR_TFAUTHJOIN = "/common/tfAuthJoin";
const API_ADDR_CODE = "/common/mtCmmCde";
const API_ADDR_AUTH = "/common/mtAuthList";
const API_ADDR_FILE = "mnt";
const API_ADDR_EZONE = "esupport";
const API_ADDR_MNG = "common";

const getCodeUrl = code => {
    if (code.startsWith("Ct")) {
        return `${API_CONTEXT_API}/${API_ADDR_EZONE}/ct${code.substring(2)}`;
    } else if (code.startsWith("Mt")) {
        return `${API_CONTEXT_API}/${API_ADDR_MNG}/mt${code.substring(2)}`;
    }
    return "";
};

export default {
    /**
     * 파일 업로드(작동 잘됨)
     */
    uploadFile: (param, callBack) => {

        axios.post(`${API_CONTEXT_API}/${API_ADDR_FILE}/mtFileList/upload`, param, { headers: { 'Access-Control-Allow-Origin': '*', "Content-Type": 'multipart/form-data' } })
            .then(function (response) {
                callBack(response);
            })
            .catch(function (error) {
                callBack(error);
            });
    },

    /**
     * 파일 다운로드(작동 잘됨)
     * vue3는 파일 다운로드 방식이 다르다 함.
     */
    downloadFile: (fileIdx, callBack) => {

        axios({
            url: `${API_CONTEXT_API}/${API_ADDR_FILE}/mtFileList/download`,
            params: { fileIdx: fileIdx },
            method: "GET",
            responseType: "blob", // 바이너리 데이터로 응답 받기 위해 responseType을 'blob'으로 설정
        }).then((response) => {
            // 파일 다운로드를 위한 코드
            const contentDisposition = response.headers["content-disposition"];
            let filename = null;

            if (contentDisposition) {
                const matches = contentDisposition.match(/filename="(.+\..+)"/);
                if (matches && matches.length > 1) {
                    filename = matches[1];
                }
            }

            if (filename == null) {
                throw new Error('강제로 예외를 발생시킵니다.');
            }

            filename = decodeURIComponent(filename);

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename); // 파일 이름과 확장자 지정
            document.body.appendChild(link);
            link.click();

            callBack(response.status);
        }).catch(() => {
            callBack(null);
        });
    },

    /**
     * 파일 목록 조회
     */
    selectFileList: (param, callBack) => {

        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_FILE}/mtFileList/selectMtFileList`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    deleteFileList: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_FILE}/mtFileList/deleteMtFileList`, { param, method: "get" }, res => {
            callBack(res);
        });
    },
    /**
     * 토큰 체크.
     */
    getToken: async () => {
       

        return new Promise((resolve) => {

            axios.post(`${API_CONTEXT_API}/getAccessToken`, {
                refreshToken: getCookie('refresh_token')
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                resolve(res);
            }).catch(error => {
                removeLocalItem('eUserId');
                removeLocalItem('eUserNm');
                removeLocalItem('searchVal');
                console.error('Error respons e:', error.response);
                resolve(error);
            });

            // GmxRequest.load(API_CONTEXT_API + '/getAccessToken', { param: {refreshToken : getCookie('refresh_token') }, method: 'get' }, (res) => {
            //     resolve(res);
            // });
        });
    },

    /**
     * 로그인
     *
     * @param {*} param
     * @param {*} callBack
     */
    signLogin: (param, callBack) => {

        const  t =  EncryptUtil.encryptRSA('ahjezoneasgeomex', param.public, param.modulus);

        param.userId = EncryptUtil.encryptLEA(param.userId, "ahjezoneasgeomex");
        param.userPwd = EncryptUtil.encryptLEA(param.userPwd, "ahjezoneasgeomex");
        param.key = t;

        //key : _CipherManager.fn.encryptRSA($("#Modulㅉus").val(), $("#Exponent").val(), TEAKey)
        //param.key = key;
        

        GmxRequest.load(API_CONTEXT_API + "/login", { param, method: "get" }, res => {
            callBack(res);
        });
    },

    /**
     * 평문 로그인
     *
     * @param {*} param
     * @param {*} callBack
     */
    plainLogin: (param, callBack) => {
        GmxRequest.load(API_CONTEXT_API + "/plainLogin", { param, method: "get" }, res => {
            callBack(res);
        });
    },
    /**
     * 로그인 암호화 키 요청
     *
     * @param {*} param
     * @param {*} callBack
     */
    loginKey: (param, callBack) => {
        GmxRequest.load(API_CONTEXT_API + "/loginKey", { param, method: "get" }, res => {
            callBack(res);
        });
    },


    /**
     * 로그아웃
     *
     * @param {*} param
     * @param {*} callBack
     */
    signLogout: (param, callBack) => {
        GmxRequest.load(API_CONTEXT_API + "/logout", { param, method: "get" }, res => {
            callBack(res);
        });
    },

    /**
     * 계정 등록
     *
     * @param {*} param
     * @param {*} callBack
     */
    addUserInfo: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_USER}/insertMtUsrDesc`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    /**
     * 계정 수정
     *
     * @param {*} param
     * @param {*} callBack
     */
    updUserInfo: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_USER}/updateMtUsrDesc`, { param, method: "get" }, res => {
            callBack(res);
        });
    },
    /**
     * 계정 수정
     *
     * @param {*} param
     * @param {*} callBack
     */
    updUserInfoNoValid: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_USER}/updateMtUsrDescNoValid`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    /**
     * 계정 삭제
     *
     * @param {*} param
     * @param {*} callBack
     */
    delUserInfo: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_USER}/select`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    /**
     * 아이디/패스워드 찾기
     * common/mtUsrDesc/selectMtUsrDesc
     * @param {d} param
     * @param {*} callBack
     */
    findUserInfo: async (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_USER}/selectMtUsrDesc`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    findSyncUserInfo: async (param) => {
        return new Promise((resolve) => {

            GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_USER}/selectMtUsrDesc`, { param, method: "get" }, res => {
                resolve(res);
            });

        });
    },

    /**
     * 부서 정보 조회
     * common/mtUsrDesc/selectMtUsrDesc
     * @param {d} param
     * @param {*} callBack
     */
    selectOrgzList: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_ORGZ}/selectMtOrgzDesc`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    /**
     * 사용자 그룹 정보 조회
     * common/mtUsrDesc/selectMtUsrDesc
     * @param {d} param
     * @param {*} callBack
     */
    selectUgrpList: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_UGRP}/selectMtAuthGrp`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    deleteUgrp: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_UGRP}/deleteMtAuthGrp`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    addUgrp: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_UGRP}/insertMtAuthGrp`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    updUgrp: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_UGRP}/updateMtAuthGrp`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    selectAuthList: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_AUTH}/selectMtAuthList`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    modAuth: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_TFAUTHJOIN}/insert`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    selectAuthJoin: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_AUTHJOIN}/selectMtAuthJoin`, { param, method: "get" }, res => {
            
            callBack(res);
        });
    },

    selectSyncAuthJoin: async (param) => {
        return new Promise((resolve) => {
            
            GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_AUTHJOIN}/selectMtAuthJoin`, { param, method: "get" }, res => {
                resolve(res);
            });

        });
    },

    selectMtCode: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_CODE}/selectMtCmmCde`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    /**
     * 업체 등급코드 정보 조회
     * common/mtUsrDesc/selectMtUsrDesc
     * @param {d} param
     * @param {*} callBack
     */
    selectAccLvlList: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/${API_ADDR_ACCLVL}/selectMtAccLvl`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    /**
     * 업체 등급코드 정보 조회
     * common/mtUsrDesc/selectMtUsrDesc
     * @param {d} param
     * @param {*} callBack
     */
    selectMtTarget: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/common/mtTarget/selectMtTargetItem`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    /**
     * 업체 등급코드 정보 조회
     * common/mtUsrDesc/selectMtUsrDesc
     * @param {d} param
     * @param {*} callBack
     */
    insertMtTarget: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/common/mtTarget/insertMtTarget`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    /**
     * 비밀번호 초기화
     *
     * @param {*} param
     * @param {*} callBack
     */
    initPassWord: (param, callBack) => {
        GmxRequest.load(`${process.env.VUE_APP_GmxRequest.load_URL}/${API_ADDR_USER}/stateAll`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    selectCommonList: (code, param, callBack) => {
        if (code == 'CtSale' && !param.projCd) param.constId = getLocalItem('eUserId');
        GmxRequest.load(`${getCodeUrl(code)}/select${code}`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    selectCommonListState: (code, param, callBack) => {
        if (code == 'CtSale') param.constId = getLocalItem('eUserId');
        GmxRequest.load(`${getCodeUrl(code)}/state${code}`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    selectCommonListStateForm: (code, param, callBack) => {
        GmxRequest.load(`${getCodeUrl(code)}/stateFormCtProj`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    selectCommonInfo: (code, param, callBack) => {
        GmxRequest.load(`${getCodeUrl(code)}/select${code}`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    selectMonitorContYear: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/esupport/monitor/selectMonitorContYear`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    selectMonitorSalesYearTable: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/esupport/monitor/selectMonitorSalesYearTable`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    selectMonitorSalesTarget: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/esupport/monitor/selectMonitorSalesTarget`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    selectMonitorCsGraphTable: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/esupport/monitor/selectMonitorCsGraphTable`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    search: (param, callBack) => {
        param['limit'] = 5;
        GmxRequest.load(`${API_CONTEXT_API}/esupport/search/totalSearch`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    searchTotal: (param, callBack) => {
        //param['limit'] = 5;
        GmxRequest.load(`${API_CONTEXT_SEARCH_API}/_search`, { param, method: "POST" }, res => {
            callBack(res);
        });
    },

    selectNewPkey: async (param) => {
        return new Promise((resolve) => {

            GmxRequest.load(`${API_CONTEXT_API}/esupport/common/selectNewPkey`, { param, method: "get" }, res => {
                resolve(JSON.parse(res.data.message).key);
            });

        });

    },

    selectCurrentPkey: (param) => {
        return new Promise((resolve) => {

            GmxRequest.load(`${API_CONTEXT_API}/esupport/common/selectCurrentPkey`, { param, method: "get" }, res => {
                resolve(JSON.parse(res.data.message).key);
            });

        });

    },

    addCommon: (code, param, callBack) => {

        const toDay = DateUtil.getYMDH24mmss();
        param['creDat'] = toDay;
        param['updDat'] = toDay;

        GmxRequest.load(`${getCodeUrl(code)}/insert${code}`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    updateCommon: (code, param, callBack) => {
        const toDay = DateUtil.getYMDH24mmss();
        param['updDat'] = toDay;

        GmxRequest.load(`${getCodeUrl(code)}/update${code}`, { param, method: "get" }, res => {
            
            callBack(res);
        });
    },

    updateCommonNoValud: (code, param, callBack) => {
        const toDay = DateUtil.getYMDH24mmss();
        param['updDat'] = toDay;

        GmxRequest.load(`${getCodeUrl(code)}/update${code}NoValid`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    deleteCommon: (code, param, callBack) => {
        const toDay = DateUtil.getYMDH24mmss();
        param['updDat'] = toDay;
        GmxRequest.load(`${getCodeUrl(code)}/delete${code}`, { param, method: "get" }, res => {
            callBack(res);
        });
    },


    xlsData: (code, param, callBack) => {
        GmxRequest.load(`${getCodeUrl(code)}/selectExcelData`, { param, method: "get" }, res => {
            callBack(res);
        });
    },
    
    sendMail : (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/esupport/common/sendMail`, { param, method: "get" }, res => {
            callBack(JSON.parse(res.data.message));
        });
    },

    //메일 발송조건 내용을 리턴합니다.
    selectMailCon: (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/esupport/common/selectMailSendConItem`, { param, method: "get" }, res => {
            callBack(res);
        });
    },

    addAccessLog : (param, callBack) => {
        GmxRequest.load(`${API_CONTEXT_API}/common/mtAccessLog/insertMtAccessLog`, { param, method: "get" }, res => {
            callBack(res);
        }); 
    }

};

