<template>
    <div :class="{active: toggleActive}" @click="toggleBtn" class="gmx_switch_wrap">
        <input type="checkbox" :checked="modelValue" @input="handleChange">
        <div class="gmx_swicth_track"></div>
        <div class="gmx_swicth_thumb"></div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits} from 'vue';

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue : {
        type : Boolean,
        default : true
    },
    label : String,
});

let toggleActive = ref(props.modelValue);

const toggleBtn = () => {
    toggleActive.value = !toggleActive.value;
    //context.emit('on-toggle', toggleActive.value);
    emit('update:modelValue', toggleActive.value);
}

function handleChange(event) {
    // 
    emit('update:modelValue', event.target.checked);
}

</script>

<style lang="scss" scoped>
.gmx_switch_wrap {
    $defalutEm: 14px; // 기본 사이즈 값
    $trackColor1 : $gmx-box-br-color; //트랙색상 기본
    $trackColor2 : rgba($gmx-main-color, 0.5); //트랙색상 액티브
    $toggleColor1 : $gmx-box-sub-br-color; // 토글색상 기본
    $toggleColor2 : $gmx-main-color; // 토글색상 액티브
    $transition: all 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15); // 전환 옵션
    
    position: relative;
    display: flex;
    align-items: center;
    width: 2.2em;
    height: 1.8em;
    font-size: $defalutEm;
    cursor: pointer;
    input[type="checkbox"] {
        display: none;
    }
    .gmx_swicth_track {
        width: 100%;
        height: 0.9em;
        background-color: $trackColor1;
        border-radius: 0.45em;
        transition: $transition;
    }
    .gmx_swicth_thumb {
        position: absolute;
        top: 50%;
        left: 15%;
        transform: translate(-50%, -50%);
        transform-origin: center;
        width: 1.2em;
        height: 1.2em;
        background-color: $toggleColor1;
        border-radius: $border-radius0;
        transition: $transition;
    }
    &:active {
        .gmx_swicth_thumb {
            width: 1.6em;
            border-radius: 0.8em;
        }    
    }
    &.active {
        .gmx_swicth_track {
            background-color: $trackColor2;
        }
        .gmx_swicth_thumb {
            left: 85%;
            background-color: $toggleColor2;
        }
    } 
}
</style>