<template>
  <template v-if="!getGuideValue">
    <div v-if="$route.path.indexOf('/guide') != -1">
      <GuideMainView></GuideMainView>
    </div>
    <router-view name="login"></router-view>
    <router-view name="main"></router-view>
    <router-view name="test"></router-view>
    <Teleport to="body">
        <GmxToast ref="toastRef"></GmxToast>
        <GmxConfirm ref="confirmRef"></GmxConfirm>
        <GmxAlert ref="alertRef"></GmxAlert>
      </Teleport>
  </template>
  <template v-else>
    <guidePage></guidePage>
  </template>
</template>

<script setup>

import { ref} from 'vue';
import GuideMainView from "@/views/guide/layout/MainView.vue";
import guidePage from "./guidePage.vue";

 import { useStore } from 'vuex';
import { GmxAlert, GmxConfirm, GmxToast } from './components/gmx';

const getGuideValue  = process.env.VUE_APP_GUIDE == 'true';
const store = useStore();
const toastRef = ref('toastRef');
const confirmRef = ref('confirmRef');
const alertRef = ref('alertRef');
if ( !getGuideValue ) {
  store.commit('gmxCommon/initRef', {key:'toast', ref:toastRef});
  store.commit('gmxCommon/initRef', {key:'confirm', ref:confirmRef});
  store.commit('gmxCommon/initRef', {key:'alert', ref:alertRef});
}

</script>
