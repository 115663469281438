<template>
    <div class="gmx_dialog" v-if="isShowRef">
        <div class="dialog_container">
            <div class="dialog_title">
                <slot name="title">
                    <div class="left_title_section">
                        <span> {{ titleRef }}</span>
                    </div>
                    <div class="right_title_section">
                        <GmxButton cl="c" @click="closeDialog"></GmxButton>
                    </div>
                </slot>
            </div>
            <div :class="`flex flex_column dialog_content dialog_default_box ${clRef}`" :style="dialogStyle">
                <slot name="content" ></slot>
            </div>
            <div v-if="$slots.footer" class="dialog_footer">
                <slot name="footer"></slot>
            </div> 
        </div>
    </div>
</template>

<script setup>

import { defineExpose, ref, defineProps } from 'vue';
import { GmxButton } from '@/components/gmx';

const props = defineProps({
    title : {
      type:String,
      default : ''
    },
    cl : {
      type : String,
      default : ''
    },
    w: {
        type : String,
        defualt : ''
    },
    h: {
        type : String,
        defualt : ''
    },
});

const dialogStyle = {
    height:props.h,
    width:props.w
}

const isShowRef = ref(false);
const titleRef = ref(props.title);
const clRef = ref(props.cl); //다른 방법이 있지 않을까?.. 고민

//cl

/**
 * template #content에서 버튼을 직접 만들어 사용하여 close를 전달받는 경우
 * Ex) EOA1004 참조.
 * @param {*} data 
 */

/**
 * 오픈.
 * 
 * @param {} _func 
 */
const showDialog = (_func) => {
    isShowRef.value = true;
    if ( typeof _func === "function" ) _func();
};


/**
 * 닫기
 * 
 * @param {*} _func 
 */
const closeDialog = (_func) => {
    isShowRef.value = false;
    if ( typeof _func === "function" ) _func();
}

defineExpose({
    showDialog,
    closeDialog
});

</script>

<style lang="scss" scoped>
.dialog_default_box {
  height: auto;
}

.gmx_dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.5);

    ::v-deep .gmx_table_wrap {
        flex: 1 0 auto;
        height: 0;
        min-height: 0;
        .gmx_table {
            flex: 1 0 auto;
            height: 0;
            min-height: 0;
            margin: 0;
        }
    }
    
    input {
        backdrop-filter: ffd;
    }

     
    .dialog_title {
        min-height: 20px;
        margin-bottom: 20px;
        span{
            font-size: $font-size7;
            font-family: $font-type2;
        }
        .right_title_section {
            position: absolute;
            top: 20px;
            right: 20px;
            ::v-deep button {
                width: 20px;
                height: 20px;
                background-image: url(@/assets/img/icn_x.png);
                background-size: contain;
            }
        }
    }
    
    .dialog_container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $gmx-box-bg-color;
        border-radius: $border-radius5;
        padding: 30px 40px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        z-index: 10;
        .history_section1 {
            margin-bottom : 10px;
        }

        .history_section1 h3 {
            font-size : $font-size4;
            font-weight : bold;
        }

        .history_section1 span {
            font-size : $font-size1;
            color : $gmx-font-sub-color2;
        }
        
        .dialog_content {
            //min-height: 150px;
            box-sizing: border-box;
            >::v-deep * {
                flex: 1 0 auto;
            }
            // &:hover  {
            //     border: none;
            //     overflow: auto;
            //     margin:0;
            //     padding:0;
            // }

            // /* 스크롤 필요해요~~ 유림 씨 */
            // /* 아래의 모든 코드는 영역::코드로 사용 */
            // &::-webkit-scrollbar {
            //     width: 10px;  /* 스크롤바의 너비 */
            //     display: none;
            // }

            // &::-webkit-scrollbar-thumb {
            //     height: 30%; /* 스크롤바의 길이 */
            //     background: var(--main-bg-color); /* 스크롤바의 색상 */
            //     border-radius: 10px;
            // }

            // &::-webkit-scrollbar-track {
            //     border-radius: 10px;
            //     background: rgba(33, 122, 244, .1);  /*스크롤바 뒷 배경 색상*/
            // }
            
        }
    }
    .dialog_footer {
        display:flex;
        justify-content: center;
        align-items: flex-end;
        height: 70px;
    }

    ::v-deep .gmx_list_table {
        height: 400px;
    }

}


</style>