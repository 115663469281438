<template>
    <span :class="classRef">
        <slot name="txt" ></slot>
    </span>
</template>
  
<script setup>

</script>

<style lang="scss" scoped>
    span {
        margin : 0 10px;
        font-size: 12px;
        color:$gmx-font-sub-color2;

    }
</style>